
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("@ember/test-waiters/build-waiter", function(){ return i("@ember/test-waiters/build-waiter");});
d("ember-test-waiters/index", function(){ return i("@ember/test-waiters/ember-test-waiters/index");});
d("@ember/test-waiters/index", function(){ return i("@ember/test-waiters/index");});
d("@ember/test-waiters/token", function(){ return i("@ember/test-waiters/token");});
d("@ember/test-waiters/types/index", function(){ return i("@ember/test-waiters/types/index");});
d("@ember/test-waiters/wait-for-promise", function(){ return i("@ember/test-waiters/wait-for-promise");});
d("@ember/test-waiters/wait-for", function(){ return i("@ember/test-waiters/wait-for");});
d("@ember/test-waiters/waiter-manager", function(){ return i("@ember/test-waiters/waiter-manager");});
import "ember-modifier/-embroider-implicit-modules.js";
import "@ember/test-waiters/-embroider-implicit-modules.js";
